import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Assalamu Alaikum`}</p>
    <p>{`I'm Mahmudul Hasan - a software engineer. I'm an expert in WISC stack.
Currently I work at `}<a parentName="p" {...{
        "href": "https://www.vivasoftltd.com/"
      }}>{`Vivasoft Limited`}</a>{` as a level 2 software engineer.`}</p>
    <p>{`I have a government registered pet company named `}<a parentName="p" {...{
        "href": "https://pienteger.com"
      }}>{`Pienteger`}</a>{` (pronounced as pie-integer).
I wish one day it will become a tech giant like Microsoft or Google. `}</p>
    <p>{`Anyway, here is a photo of me, taken in 2022, chilling in St. Martin Island, Bangladesh.`}</p>
    <img {...{
      "style": {
        "maxWidth": "100%",
        "height": "auto"
      },
      "src": "/DSC_0089_Edited.JPG",
      "alt": "A smiling photo of Mahmudul Hasan"
    }}></img>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      